import React from 'react';
import Layout from '../components/Layout';
import BlogPost from '../components/Blog-post';
import { graphql } from 'gatsby';

const BlogDetails = ({ data }) => {
  const post = data.wordpressWpDiseases;
  return (
    <Layout>
      <BlogPost
        title={post.title}
        content={post.content}
        bgUrl={post.featured_media.localFile?.publicURL}
        img={post.featured_media.localFile.childImageSharp.fluid}
        date={post.date}
      />
    </Layout>
  );
};
export default BlogDetails;

export const postQuery = graphql`
  query($id: String!) {
    wordpressWpDiseases(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      excerpt
      featured_media {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400, maxHeight: 350) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
